import type { MetaFunction } from 'react-router'

export const loader = async () => {
  return {
    message: 'Hello World',
  }
}
export const meta: MetaFunction = () => {
  return [
    { title: 'New Remix App' },
    { name: 'description', content: 'Welcome to Remix!' },
  ]
}

export default function Home() {
  return <div className="p-4">Home!</div>
}
